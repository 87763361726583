import React from "react";
import { useNavigate } from "react-router-dom";
import Bible from "../../json/biblia.json";
import "./styles.css";

const oldTestamentBooks = [
  "gn", "ex", "lv", "nm", "dt", "js", "jd", "rt", "1sm", "2sm", "1rs", "2rs", 
  "1cr", "2cr", "ed", "ne", "et", "jó", "sl", "pv", "ec", "ct", "is", "jr", 
  "lm", "ez", "dn", "os", "jl", "am", "ob", "jn", "mq", "na", "hc", "sf", 
  "ag", "zc", "ml"
];

const newTestamentBooks = [
  "mt", "mc", "lc", "jo", "atos", "rm", "1co", "2co", "gl", "ef", "fp", "cl", 
  "1ts", "2ts", "1tm", "2tm", "tt", "fm", "hb", "tg", "1pe", "2pe", "1jo", 
  "2jo", "3jo", "jd", "ap"
];

function Books() {
  const navigate = useNavigate();

  const oldTestament = Bible.filter((book) => oldTestamentBooks.includes(book.id));
  const newTestament = Bible.filter((book) => newTestamentBooks.includes(book.id));

  const handleNavigate = (book) => {
    navigate("/bible", { state: { result: book } });
  };

  return (
    <div className="books-container">
      <div className="testament">
        <h2>Velho Testamento</h2>
        {oldTestament.map((book) => (
          <button
            key={book.id}
            className="books-button"
            onClick={() => handleNavigate(book)}
          >
            {book.id.toUpperCase()} - {book.n}
          </button>
        ))}
      </div>
      <div className="testament">
        <h2>Novo Testamento</h2>
        {newTestament.map((book) => (
          <button
            key={book.id}
            className="books-button"
            onClick={() => handleNavigate(book)}
          >
            {book.id.toUpperCase()} - {book.n}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Books;
