import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./styles.css";

function Bible() {
  const location = useLocation();
  const { result, scrollTo } = location.state;

  useEffect(() => {
    if (scrollTo) {
      const { chapter, verse } = scrollTo;
      const element = document.querySelector(
        `#chapter-${chapter}-verse-${verse}`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        element.classList.add("highlight");
      }
    }
  }, [scrollTo]);

  return (
    <div className="bible-page">
      <h1>{result.n}</h1>
      <div className="chapters">
        {result.c.map((chapter) => (
          <div key={chapter.n} className="chapter" id={`chapter-${chapter.n}`}>
            <h2>Capítulo {chapter.n}</h2>
            <div className="verses">
              {chapter.v.map((verse) => (
                <p
                  key={verse.n}
                  className="verse"
                  id={`chapter-${chapter.n}-verse-${verse.n}`}
                >
                  <strong>{verse.n}</strong> {verse.text}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Bible;
