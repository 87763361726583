import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/arrow-left.svg";

import "./styles.css";

function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const { results: initialResults = [], query: initialQuery = "" } = location.state || {};

  const [query, setQuery] = useState(initialQuery);
  const [filteredResults, setFilteredResults] = useState(initialResults);

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleResultClick = (item) => {
    navigate("/hymnal", { state: { result: item } });
  };

  useEffect(() => {
    const normalizedQuery = normalizeString(query.trim());
  
    const filtered = initialResults.filter((item) => {
      const nameNormalized = normalizeString(item.name);
      const lyricsNormalized = normalizeString(item.lyrics);
      const numNormalized = item.num.toString();
  
      return (
        nameNormalized.includes(normalizedQuery) ||
        lyricsNormalized.includes(normalizedQuery) ||
        numNormalized.includes(normalizedQuery)
      );
    });
  
    setFilteredResults(filtered);
  }, [query, initialResults]);
  
  return (
    <div className="search-page">
      <section className="bar-search">
        <form>
          <button
            type="button"
            className="btn-back"
            onClick={() => navigate("/")}
          >
            <img src={back} alt="voltar" />
          </button>
          <div className="input-container">
            <input
              placeholder="Buscar"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            />
          </div>
        </form>
      </section>
      <section className="body-search">
      <div>
          {filteredResults.length === 0 ? (
            <p className="not-find">Nenhum resultado encontrado. Por favor, tente novamente.</p>
          ) : (
            filteredResults
              .sort((a, b) => a.num - b.num)
              .map((item, index) => (
                <p key={index} onClick={() => handleResultClick(item)}>
                  <strong>{item.num}</strong> - {item.name}
                </p>
              ))
          )}
        </div>
      </section>
    </div>
  );
}

export default Search;
