import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";

import biblia from "../../assets/biblia.svg";
import musica from "../../assets/musica.svg";
import orcamento from "../../assets/orcamento.svg";
import download from "../../assets/download.svg";

function BoxMenu({ onTypeChange }) {
  const navigate = useNavigate();
  const [activeBox, setActiveBox] = useState(null);

  useEffect(() => {
    setActiveBox("one");
  }, []);

  return (
    <section>
      <div className="ct-box">
        <div
          className={`box one ${activeBox === "one" ? "active" : ""}`}
          onClick={() => {
            onTypeChange("hymnal");
            setActiveBox("one");
          }}
        >
          <div>
            <img src={musica} alt="ícone" />
          </div>
          <div>
            <h1>Hinário</h1>
          </div>
        </div>

        <div
          className={`box two ${activeBox === "two" ? "active" : ""}`}
          onClick={() => {
            onTypeChange("bible");
            setActiveBox("two");
          }}
        >
          <div>
            <img src={biblia} alt="ícone" />
          </div>
          <div>
            <h1>Bíblia</h1>
          </div>
        </div>

        <div
          className={`box three ${activeBox === "three" ? "active" : ""}`}
          onClick={() => {
            setActiveBox("three");
            navigate("/download");
          }}
        >
          <div>
            <img src={download} alt="ícone" />
          </div>
          <div>
            <h1>Baixar</h1>
          </div>
        </div>

        <div
          className={`box four ${activeBox === "four" ? "active" : ""}`}
          onClick={() => {
            setActiveBox("four")
            navigate("/donate");
          }}
        >
          <div>
            <img src={orcamento} alt="ícone" />
          </div>
          <div>
            <h1>Doações</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BoxMenu;
