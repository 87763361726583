import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Hymnal from "../../json/hymnal.json";
import "./styles.css";

function Hymns() {
  const navigate = useNavigate();
  const dataset = Hymnal.hymnal;

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const groupedHymns = dataset.reduce((acc, hymn) => {
    const firstLetter = removeAccents(hymn.name[0]).toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(hymn);
    return acc;
  }, {});

  const sortedGroups = Object.keys(groupedHymns).sort();

  const [activeLetter, setActiveLetter] = useState(sortedGroups[0]);
  const handleResultClick = (item) => {
    navigate("/hymnal", { state: { result: item } });
  };

  return (
    <div className="hymns">
      <div className="hymns-nav">
        {sortedGroups.map((letter) => (
          <button
            key={letter}
            className={`nav-button ${activeLetter === letter ? "active" : ""}`}
            onClick={() => setActiveLetter(letter)}
          >
            {letter}
          </button>
        ))}
      </div>

      <div className="hymns-groups">
        {groupedHymns[activeLetter]
          .sort((a, b) => a.num - b.num)
          .map((hymn) => (
            <button
              key={hymn.num}
              className="hymn-button"
              onClick={() => handleResultClick(hymn)}
            >
              <strong>{hymn.num}</strong> - {hymn.name}
            </button>
          ))}
      </div>
    </div>
  );
}

export default Hymns;
