import React from "react";
import "./styles.css";

import download from "../../assets/download.svg";

function Download() {
  return (
    <div className="download-page">
      <div className="img-download">
        <img src={download} alt="ícone" />
      </div>
      <div className="desc-download">
        <h2>
          Baixe nosso App e acesse nossos conteúdos com mais facilidade.
        </h2>
        <p>
          Nossa plataforma está em desenvolvimento! Doe e ajude a acelerar
          esse processo.
        </p>
      </div>
      <div className="btn-apps"><div className="android">App Android </div><div className="divider-app">|</div><div className="ios">App iOS</div></div>
    </div>
  );
}

export default Download;
