import React from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import brand from "../../assets/brand-white.svg";

function Navgation() {
  const navigate = useNavigate();

  return (
    <nav>
      <div className="left-side">
        <div onClick={() => navigate("/")}>
          <img src={brand} alt="brand"></img>
        </div>
        <div onClick={() => navigate("/")}>
          <p>
            Música<strong>Plena</strong>
          </p>
        </div>
      </div>
      <div className="right-side">
        <a href="/">Entrar</a>
        <p>|</p>
        <a href="/">Cadastrar</a>
      </div>
    </nav>
  );
}

export default Navgation;
