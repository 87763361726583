import React, { useState } from "react";
import Search from "../components/Search";
import "./App.css";

function App() {
  const [type, setType] = useState("hymnal");

  const handleTypeChange = (newType) => {
    setType(newType);
  };

  return (
    <main>
      <Search type={type} onTypeChange={handleTypeChange} />
    </main>
  );
}

export default App;
