import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navgation";
import BoxMenu from "../Box";
import Bible from "../../json/biblia.json";
import Hymnal from "../../json/hymnal.json";
import search from "../../assets/search.svg";
import "./styles.css";

function Search({ type, onTypeChange }) {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [inputType, setInputType] = useState("text");

  const dataset = type === "bible" ? Bible : Hymnal.hymnal;

  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleSearch = (event) => {
    event.preventDefault();

    console.log(event);
    console.log("Var", searchBy);

    let filteredResults = [];
    const normalizedQuery = normalizeText(query.trim());

    try {
      if (type === "bible") {
        if (searchBy === "book") {
          filteredResults = dataset.filter((book) =>
            normalizeText(book.n)?.includes(normalizedQuery)
          );

          if (filteredResults.length === 1) {
            const singleResult = filteredResults[0];
            navigate("/bible", { state: { result: singleResult } });
          } else {
            navigate("/search-bible", {
              state: { query: query, searchType: "book"},
            });
          }
        } else if (searchBy === "verse") {
          filteredResults = dataset
            .flatMap((book) =>
              book.c.flatMap((chapter) => {
                if (Array.isArray(chapter.v)) {
                  const verses = chapter.v.filter((verse) =>
                    normalizeText(verse.text)?.includes(normalizedQuery)
                  );

                  return verses.length > 0 ? { book, chapter, verses } : null;
                }
                return null;
              })
            )
            .filter(Boolean);

          if (filteredResults.length === 1) {
            const { book, chapter, verses } = filteredResults[0];
            navigate("/bible", {
              state: {
                result: book,
                scrollTo: { chapter: chapter.n, verse: verses[0].n },
              },
            });
          } else {
            navigate("/search-bible", {
              state: { query: query, searchType: "verse" },
            });
          }
        }
      }

      if (type === "hymnal") {
        if (searchBy === "numb") {
          filteredResults = dataset.filter(
            (hymn) => hymn.num.toString() === normalizedQuery
          );
        } else if (searchBy === "letter") {
          filteredResults = dataset.filter(
            (hymn) =>
              normalizeText(hymn.name).includes(normalizedQuery) ||
              normalizeText(hymn.lyrics).includes(normalizedQuery)
          );
        }

        if (filteredResults.length === 1) {
          const singleResult = filteredResults[0];
          navigate("/hymnal", { state: { result: singleResult } });
        } else if (filteredResults.length > 1) {
          navigate("/search-hymnal", {
            state: { results: dataset, query: query },
          });
        } else {
          navigate("/search-hymnal", {
            state: { results: dataset, query: query },
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (
      type === "hymnal" &&
      searchBy !== "numb" &&
      searchBy !== "list" &&
      searchBy !== "letter"
    ) {
      setSearchBy("numb");
    }
    if (
      type === "bible" &&
      searchBy !== "book" &&
      searchBy !== "list" &&
      searchBy !== "verse"
    ) {
      setSearchBy("book");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (type === "hymnal") {
      if (searchBy === "numb") {
        setPlaceholder("Número do hino");
        setInputType("number");
      } else if (searchBy === "letter") {
        setPlaceholder("Refrão ou parte do hino");
        setInputType("text");
      }
    }
    if (type === "bible") {
      if (searchBy === "book") {
        setPlaceholder("Livro da bíblia");
        setInputType("text");
      } else if (searchBy === "verse") {
        setPlaceholder("Versículo ou parte do livro");
        setInputType("text");
      }
    }
  }, [type, searchBy]);

  useEffect(() => {
    if (type === "hymnal") {
      setSearchBy("numb");
    }

    if (type === "bible") {
      setSearchBy("book");
    }
  }, []); // eslint-disable-line

  return (
    <div className="search">
      <Navigation />
      <BoxMenu onTypeChange={onTypeChange} />
      <div className="bar-search">
        <form onSubmit={handleSearch}>
          <div className="input-container">
            <div className="icon-search">
              <img src={search} alt="search"></img>
            </div>
            <input
              type={inputType}
              placeholder={placeholder}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {type === "hymnal" ? (
              <>
                <button
                  type="button"
                  onClick={() => setSearchBy("letter")}
                  className={`search-letter ${
                    searchBy === "letter" ? "active" : ""
                  }`}
                >
                  aA
                </button>
                <button
                  type="button"
                  onClick={() => setSearchBy("numb")}
                  className={`search-numb ${
                    searchBy === "numb" ? "active" : ""
                  }`}
                >
                  <span className="size-numb">0</span>0
                </button>
              </>
            ) : type === "bible" ? (
              <>
                <button
                  type="button"
                  onClick={() => setSearchBy("book")}
                  className={`search-letter ${
                    searchBy === "book" ? "active" : ""
                  }`}
                >
                  Lv
                </button>
                <button
                  type="button"
                  onClick={() => setSearchBy("verse")}
                  className={`search-numb ${
                    searchBy === "verse" ? "active" : ""
                  }`}
                >
                  Vc
                </button>
              </>
            ) : null}
          </div>
        </form>
      </div>
      <div className="search-list">
        <button onClick={() => navigate("/hymns")}>Lista de Hinos</button>
        <button onClick={() => navigate("/books")}>Livros da Bíblia</button>
      </div>
    </div>
  );
}

export default Search;
