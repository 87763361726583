import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importar as páginas
import App from "../pages/App";

import Hymns from "../pages/Hymns";
import Hymnal from "../pages/Hymnal";
import SearchHymnal from "../pages/SearchHymnal";

import Books from "../pages/Books";
import Bible from "../pages/Bible";
import SearchBible from "../pages/SearchBible";

import Download from "../pages/Download";
import Donate from "../pages/Donate";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Definição das rotas */}
        <Route path="/" element={<App />} />

        <Route path="hymnal" element={<Hymnal />} />
        <Route path="search-hymnal" element={<SearchHymnal />} />
        <Route path="hymns" element={<Hymns />} />

        <Route path="books" element={<Books />} />
        <Route path="bible" element={<Bible />} />
        <Route path="search-bible" element={<SearchBible />} />

        <Route path="download" element={<Download />} />
        <Route path="donate" element={<Donate />} />

        {/* Rota para páginas não encontradas */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
