import React from "react";
import "./styles.css";

import donate from "../../assets/donate.png";

function Donate() {
  return (
    <div className="donate-page">
      <div className="img-donate">
        <img src={donate} alt="ícone" />
      </div>
      <div className="desc-donate">
        <h2>
          Apoie o desenvolvimento do nosso app e fortaleça a nossa missão.
        </h2>
        <p>
          Para 2025, precisamos de 1000 doações de <strong>R$84,00</strong>. Com a sua ajuda,
          conseguiremos!
        </p>
      </div>
      <div className="btn-apps">
        <p>
          Chave Pix: <strong>ajuda.app@icloud.com</strong>
        </p>
      </div>
      <p className="info-donate">
        Nubank - Djonatha Martins Gomes
        <br /> <br />
        WhatsApp: +55 11 97727-8055
        <br /> <br />
        Arrecadado: <strong>1 / 1000</strong>
      </p>
    </div>
  );
}

export default Donate;
