import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Bible from "../../json/biblia.json";  // Importação do arquivo JSON
import back from "../../assets/arrow-left.svg";
import "./styles.css";

function Search() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extraindo parâmetros do estado da localização
  const { query: initialQuery = "", searchType = "" } = location.state || {};

  const [query, setQuery] = useState(initialQuery);
  const [filteredResults, setFilteredResults] = useState([]);

  const normalizeString = (str) => {
    return str
      .normalize("NFD") // Remove diacríticos
      .replace(/[\u0300-\u036f]/g, "")  // Remove acentuação
      .toLowerCase();  // Converte para minúsculas
  };

  const handleResultClick = (item, scrollTo) => {
    navigate("/bible", { state: { result: item, scrollTo } });
  };

  useEffect(() => {
    const normalizedQuery = normalizeString(query.trim());

    console.log('Query normalizada:', normalizedQuery); // Depuração

    let filtered = [];
    console.log('Tipo de busca:', searchType);

    if (searchType === "book") {
      // Filtra por livros
      console.log('Entrou no filtro de livros:', searchType);
      filtered = Bible.filter((book) => {
        if (!book || !book.n) {
          console.log("Livro sem nome encontrado:", book); // Depuração
          return false;
        }

        const bookNameNormalized = normalizeString(book.n || '');  // Normalizar nome do livro
        console.log(`Livro: ${book.n}, Normalizado: ${bookNameNormalized}`); // Depuração

        // Verificar se o nome do livro normalizado inclui a consulta normalizada
        return bookNameNormalized.includes(normalizedQuery);
      });
    } else if (searchType === "verse") {
      // Filtra por versículos
      console.log('Entrou no filtro de versículos:', searchType);
      filtered = Bible.flatMap((book) => {
        return book.c.flatMap((chapter, chapterIndex) => {
          return Array.isArray(chapter.v)
            ? chapter.v
                .filter((verse) =>
                  normalizeString(verse.text).includes(normalizedQuery)
                )
                .map((verse, verseIndex) => ({
                  book,
                  chapter,
                  chapterIndex,
                  verse,
                  verseIndex,
                }))
            : [];
        });
      });
    }

    // Log para verificar quantos resultados filtrados
    console.log(`Resultados filtrados: ${filtered.length}`, filtered);
    
    setFilteredResults(filtered);
  }, [query, searchType]);

  return (
    <div className="search-page">
      <section className="bar-search">
        <form>
          <button
            type="button"
            className="btn-back"
            onClick={() => navigate("/")}
          >
            <img src={back} alt="voltar" />
          </button>
          <div className="input-container">
            <input
              placeholder="Buscar livro ou versículo"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            />
          </div>
        </form>
      </section>
      <section className="body-search">
        <div>
          {filteredResults.length === 0 ? (
            <p className="not-find">
              Nenhum livro ou versículo encontrado. Por favor, tente novamente.
            </p>
          ) : (
            filteredResults.map((item, index) => (
              <div key={index}>
                {searchType === "book" && (
                  <p
                    onClick={() => handleResultClick(item)} // Passando o item diretamente
                    className="book-title"
                  >
                    <strong>{(item.id || '').toUpperCase()}</strong> - {item.n || ''}
                  </p>
                )}
                {searchType === "verse" && (
                  <p
                    onClick={() =>
                      handleResultClick(item.book, {
                        chapter: item.chapterIndex + 1,
                        verse: item.verse.n,
                      })
                    }
                    className="verse-result"
                  >
                    <strong>
                      Capítulo {item.chapterIndex + 1} Versículo {item.verse.n}:
                    </strong>{" "}
                    {item.verse.text}
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
}

export default Search;
