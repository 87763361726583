import React from "react";
import { useLocation } from "react-router-dom";

import "./styles.css";

function Hymnal() {
  const location = useLocation();
  const { result } = location.state;

  return (
    <div className="result">
      <h1>
        <strong>{result.num}</strong> - {result.name}
      </h1>
      <p dangerouslySetInnerHTML={{ __html: result.lyrics }}></p>
    </div>
  );
}

export default Hymnal;
